import React from 'react';
import { FaAt, FaFacebook, FaGripLinesVertical, FaTwitter, FaInstagram } from 'react-icons/fa';

export const Footer = () => (
  <div className="footer-wrapper">
    <div className="copyright">© {new Date().getFullYear()} | Oberhof Getränke | <h6><a href="mailto:info@oberhofdrinks.com" target="_blank" rel="noopener noreferrer"><FaAt title="Email" /></a> 
    <FaGripLinesVertical /> <a href="https://www.facebook.com/oberhofdrinks/" target="_blank" rel="noopener noreferrer"><FaFacebook title="Facebook" /></a> 
    <FaGripLinesVertical /> <a href="https://twitter.com/oberhofdrinks" target="_blank" rel="noopener noreferrer"><FaTwitter title="Twitter" /></a> 
<FaGripLinesVertical /> <a href="https://www.instagram.com/oberhof_drinks/" target="_blank" rel="noopener noreferrer"><FaInstagram title="Instagram" /></a></h6>
         | <a href="/PrivacyPolicy" title="Privacy Policy" class="text-muted">Privacy Policy</a> |
        

</div>
    <style jsx>{`
      a.view-more {
        font-size: 14px;
      }

      .footer-wrapper {
        text-align: center;
        margin-top: 80px;
        padding: 80px 30px;
      }
      .copyright {
        margin-bottom: 10px;
      }
    `}</style>
  </div>
)
