import React from "react";
import { FaExpand, FaHome, FaBarcode, FaAddressCard, FaGripLinesVertical, FaQuestion } from 'react-icons/fa';
import { Jumbotron } from 'react-bootstrap';
import { Helmet } from "react-helmet";




export const NoMatch = () => (
    <>
        <Helmet>
            <title>404 Seite nicht gefunden | Oberhof Getränke®</title>
            <meta name="description" content="404 - Seite nicht gefunden" />
            <meta name="keywords" content="404, Seite nicht gefunden" />
        </Helmet>

        <div>
            <Jumbotron style={{
                width: '100%',
                textAlign: 'center',
                margin: '40px auto 30px auto',
                boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
            }}>
                <h1 class="text-muted">Oberhof Getränke®</h1>

                <p class="text-muted">
                Du bist jetzt hier <FaExpand title="404 - Seite nicht gefunden" /> Mitten im Nirgendwo aka 404 Seite nicht gefunden</p>
                <h3><a href="/"><FaHome title="Startseite" /></a> <FaGripLinesVertical title="nur linien" /> <a href="/Products"><FaBarcode title="Produkte" /></a> <FaGripLinesVertical title="nur linien" /> <a href="/Faq"><FaQuestion title="Häufig gestellte Fragen" /></a> <FaGripLinesVertical title="nur linien" /> <a href="/AboutUs"><FaAddressCard title="Über uns" /></a></h3>
                <p class="text-muted">Es ist alles Saft und alles über den Saft.</p>
            </Jumbotron>
        </div>

    </>
)
