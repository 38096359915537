import React from 'react';
import { FaBarcode, FaGripLinesVertical } from 'react-icons/fa';
import { Tab, Tabs, Container, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import index from './assets/products/index.png'; // with require

import appleJt2 from './assets/products/2.png'; // with require
import carrotJt2 from './assets/products/5.png'; // with require
import mangoJt2 from './assets/products/11.png'; // with require
import mixedJt2 from './assets/products/14.png'; // with require
import kiwiJt2 from './assets/products/7.png'; // with require
import orangeJt2 from './assets/products/16.png'; // with require
import pineJt2 from './assets/products/23.png'; // with require
import pearJt2 from './assets/products/20.png'; // with require
import tomatoJt2 from './assets/products/29.jpg'; // with require
import pomegranateJt2 from './assets/products/25.png'; // with require


import carrotGlass from './assets/products/4.png'; // with require
import appleGlass from './assets/products/1.png'; // with require
import kiwiGlass from './assets/products/8.png'; // with require
import mangoGlass from './assets/products/10.png'; // with require
import mixedGlass from './assets/products/13.png'; // with require
import orangeGlass from './assets/products/17.png'; // with require
import pearGlass from './assets/products/19.png'; // with require
import pineGlass from './assets/products/22.png'; // with require
import pomegranateGlass from './assets/products/26.png'; // with require
import tomatoGlass from './assets/products/28.png'; // with require


import appleNt2 from './assets/products/3.png'; // with require
import carrotNt2 from './assets/products/6.png'; // with require
import kiwiNt2 from './assets/products/9.png'; // with require
import mangoNt2 from './assets/products/12.png'; // with require
import mixedNt2 from './assets/products/15.png'; // with require
import orangeNt2 from './assets/products/18.png'; // with require
import pearNt2 from './assets/products/21.png'; // with require
import pineNt2 from './assets/products/24.png'; // with require
import pomegranateNt2 from './assets/products/27.png'; // with require
import tomatoNt2 from './assets/products/30.png'; // with require


import strawberryFt2 from './assets/products/31.png'; // with require
import appleFt2 from './assets/products/32.png'; // with require
import mixedFt2 from './assets/products/33.png'; // with require
import apricotFt2 from './assets/products/34.png'; // with require
import mangoFt2 from './assets/products/35.png'; // with require
import orangeFt2 from './assets/products/36.png'; // with require
import cherryFt2 from './assets/products/37.png'; // with require


import appleJt1 from './assets/products/appleJt1.png'; // with require
import carrotJt1 from './assets/products/carrotJt1.png'; // with require
import kiwiJt1 from './assets/products/kiwiJt1.png'; // with require
import mangoJt1 from './assets/products/mangoJt1.png'; // with require
import mixedJt1 from './assets/products/mixedJt1.png'; // with require
import orangeJt1 from './assets/products/orangeJt1.png'; // with require
import pearJt1 from './assets/products/pearJt1.png'; // with require
import pineJt1 from './assets/products/pineJt1.png'; // with require
import pomegranateJt1 from './assets/products/pomegranateJt1.png'; // with require
import tomatoJt1 from './assets/products/tomatoJt1.png'; // with require


import appleNt1 from './assets/products/appleNt1.png'; // with require
import carrotNt1 from './assets/products/carrotNt1.png'; // with require
import kiwiNt1 from './assets/products/kiwiNt1.png'; // with require
import mangoNt1 from './assets/products/mangoNt1.png'; // with require
import mixedNt1 from './assets/products/mixedNt1.png'; // with require
import orangeNt1 from './assets/products/orangeNt1.png'; // with require
import pearNt1 from './assets/products/pearNt1.png'; // with require
import pineNt1 from './assets/products/pineNt1.png'; // with require
import pomegranateNt1 from './assets/products/pomegranateNt1.png'; // with require
import tomatoNt1 from './assets/products/tomatoNt1.png'; // with require



export const Products = () => (
    <>

        <Helmet>
            <title>Produkte: Smoothie | Saft | Nektar | Aromatisierte Getränke von Oberhof Getränke®</title>
            <meta name="description" content="Verpackung - Haltbarkeit - Versand - Versandinformationen von Smoothie | Saft | Nektar | Aromatisierte Getränkeprodukte." />
            <meta name="keywords" content="Saft, Nektar, aromatisierte Getränke, 200 ml, 1 l, Apfel, Karotte, Mango, Mischobst, Kiwi, Orange, Ananas, Birne, Tomate, Granatapfel, Aprikose, Kirsche, Paket, Regal, Leben, Versand, Laden, NFC, Quitte , Traube, lila Karotte, Melone, Wassermelone, schwarze Maulbeere, Feige, Smoothie, Cranberry, Pflaume, Brombeere, Gemüse" />
        </Helmet>


        <div>
            <h2><FaBarcode title="Produkte" /> <FaGripLinesVertical /> Produkte</h2>
        </div>


        <Tabs defaultActiveKey="smoothie" id="uncontrolled-tab-example">

            <Tab eventKey="1" title="| Smoothie |">
                <h5 class="text-muted">100% NFC-Saft (nicht aus Konzentrat) | Bio</h5>
                <div className="grid">
                    <figure className="effect-bubba">
                        <h5>Apfelsaft</h5>
                        <img src={index} alt="Apfelsaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Quittensaft</h5>
                        <img src={index} alt="Quittensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Traubensaft</h5>
                        <img src={index} alt="Traubensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Birnensaft</h5>
                        <img src={index} alt="Birnensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Lila Karottensaft</h5>
                        <img src={index} alt="Lila Karottensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Aprikosensaft</h5>
                        <img src={index} alt="Aprikosensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Melonensaft</h5>
                        <img src={index} alt="Melonensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Granatapfelsaft</h5>
                        <img src={index} alt="Granatapfelsaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Wassermelonensaft</h5>
                        <img src={index} alt="Wassermelonensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kirschsaft</h5>
                        <img src={index} alt="Kirschsaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Schwarzer Maulbeersaft</h5>
                        <img src={index} alt="Schwarzer Maulbeersaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Feigensaft</h5>
                        <img src={index} alt="Feigensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Cranberrysaft</h5>
                        <img src={index} alt="Cranberry-Saft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pflaumensaft</h5>
                        <img src={index} alt="Pflaumensaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Brombeersaft</h5>
                        <img src={index} alt="Brombeersaft | Bio 100% NFC Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche <br />3000ml Tasche</p>
                        </figcaption>
                    </figure>
                </div>

                <h5 class="text-muted">Funktional | Bio-Fruchtsaft mit Eiweiß</h5>
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Fruchtmischung mit Protein</h5>
                        <img src={index} alt="Bio-Fruchtpüree | Bio-Fruchtsaft mit Eiweiß" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche<br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>

                <h5 class="text-muted">Detox | Bio Obst & Gemüse Mix Saft</h5>
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Gemischte Früchte & Gemüse</h5>
                        <img src={index} alt="Gemischte Früchte & Gemüse | Bio Obst & Gemüse Mix Saft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche<br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>

                <h5 class="text-muted">Limonade | Bio Limonade mit Fruchtsaft</h5>
                <div className="grid">
                    <figure className="effect-bubba">
                        <h5>Limonade mit Fruchtsaft</h5>
                        <img src={index} alt="Limonade mit Fruchtsaft | Bio Limonade mit Fruchtsaft" />
                        <figcaption>
                            <p>250ml Glasflasche<br />330ml Glasflasche<br />414ml Glasflasche<br />700ml Glasflasche<br />1000ml Glasflasche<br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>
                <h5 class="text-muted">Smoothie | Bio-Fruchtpüree</h5>
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Bio-Fruchtpüree</h5>
                        <img src={index} alt="Bio-Fruchtpüree | Beutel | 90ml" />
                        <figcaption>
                            <p>90ml<br />Doypack Pouch (Beutel) mit Anti-Choke-Kappe<br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>
                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Packung:</h4>
                            <p class="text-muted">Glasflasche</p>
                            <p class="text-muted">Aseptische Tasche</p>
                            <p class="text-muted">Beutel (Pouch)</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Haltbarkeitsdauer:</h4>
                            <h5>Glasflasche</h5>
                            <p class="text-muted">18 Months</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Versand | Laden:</h4>
                            <h5>Glasflasche</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 24*250ml – 5292 Case <br />20 Ft Ctn (14 Pallet) - 24*250ml – 2646 Case</p>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 24*330ml – 4928 Case <br />20 Ft Ctn (14 Pallet) - 24*330ml – 2464 Case</p>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*414ml – 6664 Case <br />20 Ft Ctn (14 Pallet) - 6*414ml – 3332 Case</p>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*700ml – 5488 Case <br />20 Ft Ctn (14 Pallet) - 6*700ml – 2744 Case</p>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*1000ml – 3864 Case <br />20 Ft Ctn (14 Pallet) - 6*1000ml – 1932 Case</p>
                            <h5>Aseptische Tasche</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 1*3KG – 8904 Case <br />20 Ft Ctn (14 Pallet) - 1*3KG – 4452 Case</p>
                            <h5>Beutel (Pouch)</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*120gr – 21868 Case <br />20 Ft Ctn (14 Pallet) - 6*120gr – 10934 Case</p>

                        </Col>
                    </Row>
                </Container>
            </Tab>


            <Tab eventKey="saft" title="| Fruchtsaft |">
            <h5 class="text-muted">1L</h5>
                <div className="grid">
                    <figure className="effect-bubba">
                        <h5>Apfelsaft</h5>
                        <img src={appleJt1} alt="Apfelsaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Karottensaft</h5>
                        <img src={carrotJt1} alt="Karottensaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mangosaft</h5>
                        <img src={mangoJt1} alt="Mangosaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Gemischter Fruchtsaft</h5>
                        <img src={mixedJt1} alt="Gemischter Fruchtsaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwisaft</h5>
                        <img src={kiwiJt1} alt="Kiwisaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orangensaft</h5>
                        <img src={orangeJt1} alt="Orangensaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Ananassaft</h5>
                        <img src={pineJt1} alt="Ananassaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Birnensaft</h5>
                        <img src={pearJt1} alt="Birnensaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomatensaft</h5>
                        <img src={tomatoJt1} alt="Tomatensaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Granatapfelsaft</h5>
                        <img src={pomegranateJt1} alt="Granatapfelsaft | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>

                <h5 class="text-muted">200ml</h5>
                <div className="grid">
                    <figure className="effect-bubba">
                        <h5>Apfelsaft</h5>
                        <img src={appleJt2} alt="Apfelsaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Karottensaft</h5>
                        <img src={carrotJt2} alt="Karottensaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mangosaft</h5>
                        <img src={mangoJt2} alt="Mangosaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Gemischter Fruchtsaft</h5>
                        <img src={mixedJt2} alt="Gemischter Fruchtsaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwisaft</h5>
                        <img src={kiwiJt2} alt="Kiwisaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orangensaft</h5>
                        <img src={orangeJt2} alt="Orangensaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Ananassaft</h5>
                        <img src={pineJt2} alt="Ananassaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Birnensaft</h5>
                        <img src={pearJt2} alt="Birnensaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomatensaft</h5>
                        <img src={tomatoJt2} alt="Tomatensaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Granatapfelsaft</h5>
                        <img src={pomegranateJt2} alt="Granatapfelsaft | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>

                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Apfelsaft</h5>
                        <img src={appleGlass} alt="Apfelsaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Karottensaft</h5>
                        <img src={carrotGlass} alt="Karottensaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mangosaft</h5>
                        <img src={mangoGlass} alt="Mangosaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Gemischter Fruchtsaft</h5>
                        <img src={mixedGlass} alt="Gemischter Fruchtsaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwisaft</h5>
                        <img src={kiwiGlass} alt="Kiwisaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orangensaft</h5>
                        <img src={orangeGlass} alt="Orangensaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Ananassaft</h5>
                        <img src={pineGlass} alt="Ananassaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Birnensaft</h5>
                        <img src={pearGlass} alt="Birnensaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomatensaft</h5>
                        <img src={tomatoGlass} alt="Tomatensaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Granatapfelsaft</h5>
                        <img src={pomegranateGlass} alt="Granatapfelsaft | Glasflasche | 200ml" />
                        <figcaption>
                            <p>Glasflasche | 200ml<br />1 Kiste: 24*200ml<br />Haltbarkeitsdauer: 18 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Packung:</h4>
                            <p class="text-muted">Tetra Pak – 1L <br />Glasflasche – 1L</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Haltbarkeitsdauer:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Monate</p>
                            <h5>Glasflasche</h5>
                            <p class="text-muted">18 Monate</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Versand | Laden:</h4>
                            <h5>Für 1L Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 12*1L – 1680 Kiste  <br />20 Ft Ctn (14 Pallet) - 12*1L – 800 Kiste</p>
                            <h5>Für 1L Glasflasche</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*1L – 2750 Kiste <br />20 Ft Ctn (14 Pallet) - 6*1L – 1680 Kiste</p>
                        </Col>
                    </Row>
                </Container>


                <Container>
                    <Row>
                        <Col sm={3}>

                            <p class="text-muted">Tetra Pak – 200 ML</p>
                        </Col>
                        <Col sm={3}>

                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Monate</p>
                        </Col>
                        <Col sm={6}>

                            <h5>Für 200ml Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 27*200 ML – 3600 Kiste  <br />20 Ft Ctn (14 Pallet) - 27*200 ML – 2250 Kiste</p>
                        </Col>
                    </Row>
                </Container>



                <Container>
                    <Row>
                        <Col sm={3}>

                            <p class="text-muted">Glasflasche – 200 ML</p>
                        </Col>
                        <Col sm={3}>

                            <h5>Glasflasche</h5>
                            <p class="text-muted">18 Monate</p>
                        </Col>
                        <Col sm={6}>

                            <h5>Für 200ml Glasflasche</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 24*200 ML – 2750 Kiste <br />20 Ft Ctn (14 Pallet) - 24*200 ML – 1680 Kiste</p>
                        </Col>
                    </Row>
                </Container>




            </Tab>

            <Tab eventKey="nektar" title="| Fruchtnektar |">

            <h5 class="text-muted">1L</h5>
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Apfelnektar</h5>
                        <img src={appleNt1} alt="Apfelnektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Karotten-Nektar</h5>
                        <img src={carrotNt1} alt="Karotten-Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango-Nektar</h5>
                        <img src={mangoNt1} alt="Mango-Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Gemischter Fruchtnektar</h5>
                        <img src={mixedNt1} alt="Gemischter Fruchtnektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwi-Nektar</h5>
                        <img src={kiwiNt1} alt="Kiwi-Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Nektar</h5>
                        <img src={orangeNt1} alt="Orange Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Ananas-Nektar</h5>
                        <img src={pineNt1} alt="Ananas-Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Birnen-Nektar</h5>
                        <img src={pearNt1} alt="Birnen-Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomaten-Nektar</h5>
                        <img src={tomatoNt1} alt="Tomaten-Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Granatapfel-Nektar</h5>
                        <img src={pomegranateNt1} alt="Granatapfel-Nektar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Kiste: 12*1L<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                </div>



                <h5 class="text-muted">200ml</h5>
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Apfelnektar</h5>
                        <img src={appleNt2} alt="Apfelnektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Karotten-Nektar</h5>
                        <img src={carrotNt2} alt="Karotten-Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango-Nektar</h5>
                        <img src={mangoNt2} alt="Mango-Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Gemischter Fruchtnektar</h5>
                        <img src={mixedNt2} alt="Gemischter Fruchtnektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwi-Nektar</h5>
                        <img src={kiwiNt2} alt="Kiwi-Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Nektar</h5>
                        <img src={orangeNt2} alt="Orange Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Ananas-Nektar</h5>
                        <img src={pineNt2} alt="Ananas-Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Birnen-Nektar</h5>
                        <img src={pearNt2} alt="Birnen-Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomaten-Nektar</h5>
                        <img src={tomatoNt2} alt="Tomaten-Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Granatapfel-Nektar</h5>
                        <img src={pomegranateNt2} alt="Granatapfel-Nektar | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>

                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Packung:</h4>
                            <p class="text-muted">Tetra Pak – 1L <br />Glasflasche – 1L</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Haltbarkeitsdauer:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Monate</p>
                            <h5>Glasflasche</h5>
                            <p class="text-muted">18 Monate</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Versand | Laden:</h4>
                            <h5>Für 1L Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 12*1L – 1680 Kiste  <br />20 Ft Ctn (14 Pallet) - 12*1L – 800 Kiste</p>
                            <h5>Für 1L Glasflasche</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*1L – 2750 Kiste <br />20 Ft Ctn (14 Pallet) - 6*1L – 1680 Kiste</p>
                        </Col>
                    </Row>
                </Container>



                <Container>
                    <Row>
                        <Col sm={3}>

                            <p class="text-muted">Tetra Pak – 200 ML</p>
                        </Col>
                        <Col sm={3}>

                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Monate</p>
                        </Col>
                        <Col sm={6}>

                            <h5>Für 200ml Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 27*200 ML – 3600 Kiste  <br />20 Ft Ctn (14 Pallet) - 27*200 ML – 2250 Kiste</p>
                        </Col>
                    </Row>
                </Container>

            </Tab>


            <Tab eventKey="flavored" title="| Aromatisierte Getränke |">
            <h5 class="text-muted">200ml</h5>
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Erdbeere Aromatisierte</h5>
                        <img src={strawberryFt2} alt="Erdbeere Aromatisierte Getränke | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Apfel Aromatisierte</h5>
                        <img src={appleFt2} alt="Apfel Aromatisierte Getränke | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mischgetränke</h5>
                        <img src={mixedFt2} alt="Mischgetränke | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Aprikose Aromatisierte</h5>
                        <img src={apricotFt2} alt="Aprikose Aromatisierte Getränke | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango Aromatisierte</h5>
                        <img src={mangoFt2} alt="Mango Aromatisierte Getränke | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Aromatisierte</h5>
                        <img src={orangeFt2} alt="Orange Aromatisierte Getränke | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kirsche Aromatisierte</h5>
                        <img src={cherryFt2} alt="Kirsche Aromatisierte Getränke | Tetra Pak | 200ml" />
                        <figcaption>
                            <p>Tetra Pak | 200ml<br />1 Kiste: 27*200ml<br />Haltbarkeitsdauer: 12 Monate <br />Oberhof Getränke®</p>
                        </figcaption>
                    </figure>

                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Packung:</h4>
                            <p class="text-muted">Tetra Pak – 200 ML</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Haltbarkeitsdauer:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Monate</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Versand | Laden:</h4>
                            <h5>Für 200ml Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 27*200 ML – 3600 Kiste  <br />20 Ft Ctn (14 Pallet) - 27*200 ML – 2250 Kiste</p>
                        </Col>
                    </Row>
                </Container>

            </Tab>


        </Tabs>



        <style jsx>{`

}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
}
  @media (max-width: 480px) {
    .hello {
      left: 30px;
      right: 30px;
      font-size: 18px;
      padding: 20px;
    }
    h1 {
      font-size: 28px;
    }
  }
.grid {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
grid-gap: 30px;
max-width: 1280px;
margin: 0 auto 30px;

}

/* Common style */
.grid figure {
position: relative;
float: left;
overflow: hidden;
margin: 10px 1%;
min-width: 320px;
max-width: 480px;
max-height: auto;
width: 100%;
background: #ffffff;
text-align: center;
cursor: pointer;
}

.grid figure img {
position: relative;
display: block;
min-height: auto;
max-width: auto;
opacity: 0.8;

}

.grid figure figcaption {
padding: 1em;
color: #000000;
font-size: 1.6em;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
z-index: 1000;
text-indent: 200%;
white-space: nowrap;
font-size: 0;
opacity: 0;
}

.grid figure p {
letter-spacing: 1px;
font-size: 55%;
}

/* Individual effects */

/
/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
background: #ffffff;
}

figure.effect-bubba img {
opacity: 1;
-webkit-transition: opacity 0.35s;
transition: opacity 0.35s;
}

figure.effect-bubba:hover img {
opacity: 0.4;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
position: absolute;
top: 20px;
right: 40px;
bottom: 20px;
left: 40px;
content: '';
opacity: 0;
-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
border-top: 1px solid #000000;
border-bottom: 1px solid #000000;
-webkit-transform: scale(0,1);
transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
border-right: 1px solid #000000;
border-left: 1px solid #000000;
-webkit-transform: scale(1,0);
transform: scale(1,0);
}

figure.effect-bubba h2 {
padding-top: 30%;
-webkit-transition: -webkit-transform 0.35s;
transition: transform 0.35s;
-webkit-transform: translate3d(0,-20px,0);
transform: translate3d(0,-20px,0);
}

figure.effect-bubba p {
padding: 20px 2.5em;
opacity: 0;
-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
transition: opacity 0.35s, transform 0.35s;
-webkit-transform: translate3d(0,20px,0);
transform: translate3d(0,20px,0);
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
opacity: 1;
-webkit-transform: scale(1);
transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
opacity: 1;
-webkit-transform: translate3d(0,0,0);
transform: translate3d(0,0,0);
}

@media screen and (max-width: 50em) {
.content {
    padding: 0 10px;
    text-align: center;
}
.grid figure {
    display: inline-block;
    float: none;
    margin: 10px auto;
    width: 100%;
}
}
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { display: table; content: ''; }
.clearfix:after { clear: both; }

body {
background: #2f3238;
color: #fff;
font-weight: 500;
font-size: 2em;
font-family: 'Roboto', sans-serif;
}

a {
outline: none;
color: #3498db;
text-decoration: none;
}

a:hover, a:focus {
color: #528cb3;
}

section {
padding: 1em;
text-align: center;
}

.content {
margin: 0 auto;
max-width: 1000px;
}

.content > h2 {
clear: both;
margin: 0;
padding: 4em 1% 0;
color: #484B54;
font-weight: 800;
font-size: 1.5em;
}

.content > h2:first-child {
padding-top: 0em;
}

/* Header */
.codrops-header {
margin: 0 auto;
padding: 4em 1em;
text-align: center;
}

.codrops-header h1 {
margin: 0;
font-weight: 800;
font-size: 4em;
line-height: 1.3;
}

.codrops-header h1 span {
display: block;
padding: 0 0 0.6em 0.1em;
color: #74777b;
font-weight: 300;
font-size: 45%;
}

/* Demo links */
.codrops-demos {
clear: both;
padding: 1em 0 0;
text-align: center;
}

.content + .codrops-demos {
padding-top: 5em;
}

.codrops-demos a {
display: inline-block;
margin: 0 5px;
padding: 1em 1.5em;
text-transform: uppercase;
font-weight: bold;
}

.codrops-demos a:hover,
.codrops-demos a:focus,
.codrops-demos a.current-demo {
background: #3c414a;
color: #fff;
}

/* To Navigation Style */
.codrops-top {
width: 100%;
text-transform: uppercase;
font-weight: 800;
font-size: 0.69em;
line-height: 2.2;
}

.codrops-top a {
display: inline-block;
padding: 1em 2em;
text-decoration: none;
letter-spacing: 1px;
}

.codrops-top span.right {
float: right;
}

.codrops-top span.right a {
display: block;
float: left;
}

.codrops-icon:before {
margin: 0 4px;
text-transform: none;
font-weight: normal;
font-style: normal;
font-variant: normal;
font-family: 'codropsicons';
line-height: 1;
speak: none;
-webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
content: "001";
}

.codrops-icon-prev:before {
content: "004";
}

/* Related demos */
.related {
clear: both;
padding: 6em 1em;
font-size: 120%;
}

.related > a {
display: inline-block;
margin: 20px 10px;
padding: 25px;
border: 1px solid #4f7f90;
text-align: center;
}

.related a:hover {
border-color: #39545e;
}

.related a img {
max-width: 100%;
opacity: 0.8;
}

.related a:hover img,
.related a:active img {
opacity: 1;
}

.related a h3 {
margin: 0;
padding: 0.5em 0 0.3em;
max-width: 300px;
text-align: left;
}

/* Demo ad design */
body #cdawrap {
background: none;
top: 50px;
border: none;
}

body #cdawrap a {
color: #fff !important;
}

body #cda-remove {
color: #fff;
}

@media screen and (max-width: 25em) {
.codrops-header {
    font-size: 75%;
}
.codrops-icon span {
    display: none;
}
}

`}</style>






    </>
)
