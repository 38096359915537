import React from 'react';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';
import { FaAddressCard, FaGripLinesVertical } from 'react-icons/fa';
import { Helmet } from "react-helmet";


import barracuda from './assets/barracuda-ltd-1200-275.png'; // with require


export const AboutUs = () => (
    <>

        <Helmet>
            <title>Oberhof Getränke® Über uns</title>
            <meta name="description" content="Unternehmensinformationen, Kontakt, Adresse" />
            <meta name="keywords" content="barracuda turk export, barracuda ic & dis ticaret, Kontakt, Adresse, Firma" />
        </Helmet>

        <div>
            <h2><FaAddressCard title="Über uns" /> <FaGripLinesVertical /> Über uns</h2>
        </div>

        <Container>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <h3 class="text-muted">Barracuda Turk Export® <br />Barracuda Domestic and Foreign Trade Limited</h3>
                    <p class="text-muted">
                        Barracuda Limited wurde im Januar 2020 in Großbritannien gegründet, um im internationalen Handel tätig zu sein. Aufgrund der 14-jährigen Erfahrung im Finanzbereich und der 6-jährigen Erfahrung in der internationalen Handelszone in der Türkei hat der Gründer Bilge Yildirim die Aktivitäten nach Großbritannien verlegt. Barracuda unterstützt Unternehmen nicht nur beim Import und Export, sondern liefert auch Getränke mit der eingetragenen Marke „Oberhof Drinks“.</p>

                    <p class="text-muted">Oberhof Getränke; Barracuda liefert alkoholfreie Getränke (Fruchtsäfte, Smoothies, Bio-Säfte, Nektar) von seiner eigenen Marke „Oberhof Drinks“. Die Produktion wird in der Türkei hergestellt. Wir sind offen für Unternehmen, die gerne Distributor werden möchten.</p>

                    <p class="text-muted">Wir bieten unseren Kunden 2 Optionen für die Herstellung von Oberhof Drinks® Produkten; Österreich und die Türkei. Insbesondere unsere Kunden in Großbritannien, im Nahen Osten und in arabischen Ländern bevorzugen die Produktion in der Türkei, da die Logistikkosten vorteilhafter sind. </p>

                </Col>
            </Row>
        </Container>

        <Container>
            <Row className="justify-content-md-center">
                <Col sm={8}>
                    <img src={barracuda} alt="Barracuda Turk Export" />
                    <p class="text-muted">Barracuda Turk Export® ist mit seiner eingetragenen Marke Oberhof Drinks® in der Getränkegruppe in den Fruchtsaftmarkt eingetreten. Unser Ziel ist es, mit seinen natürlichen und hochwertigen Produkten die weltweit bevorzugte Marke zu werden. </p>
                    <p class="text-muted">Oberhof Drinks® Fruchtsäfte werden in Österreich und der Türkei gemäß den Anforderungen der International Food Standards (IFS) abgefüllt.</p>
                </Col>
                <Col sm={4}>
                    <Jumbotron style={{
                        width: '100%',
                        margin: '40px auto 30px auto',
                        boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
                    }}>

                        <h3>Adresse || Kontakt</h3>
                        <p class="text-muted">Barracuda Domestic & Foreign Trade Ltd</p>
                        <p class="text-muted">7 Bell Yard, London  <br />  England, WC2A 2JR</p>
                        <p class="text-muted">info@oberhofdrinks.com</p>
                        <p class="text-muted">Company number: 12389616</p>
                        <center><Button variant="outline-secondary" href="https://barracuda.ltd" title="Webseite (Englisch)" target="_blank">Webseite</Button></center>

                    </Jumbotron>
                </Col>
            </Row>
        </Container>

        <style>{`

img {
  max-width: 100%;
}

`}</style>

    </>
)

