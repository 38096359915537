import React from 'react';
import { Tab, Tabs, Accordion, Card } from 'react-bootstrap'
import { FaGripLinesVertical, FaQuestion } from 'react-icons/fa';
import { Helmet } from "react-helmet";


export const Faq = () => (
  <>

    <Helmet>
      <title>Oberhof Getränke® Häufig gestellte Fragen</title>
      <meta name="description" content="Häufig gestellte Fragen" />
      <meta name="keywords" content="was, wo, wann, warum, Oberhof Drinks" />
    </Helmet>


    <div>
      <h2><FaQuestion title="Häufig gestellte Fragen" /> <FaGripLinesVertical /> Häufig gestellte Fragen</h2>
    </div>

    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
      <Tab eventKey="homehow" title="| Wie |">


        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Wie (Kontakt)?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">Wenn Sie der gesegnete Chuck Norris sind, brauchen Sie den Rest nicht zu lesen, offensichtlich nicht, dann ist es wirklich so einfach.
Finden Sie einfach die richtige Art der Kommunikation von unten, wie z. B. E-Mail, und jemand wird sich so schnell wie möglich bei Ihnen melden.<br /><br />
                Entweder Sie verwenden die älteste Rauchmethode, aber wir sind nicht sehr gut darin, sie zu lesen, nur | arbeite immer noch daran.
</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Wie (Versand)?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Text class="text-muted">Unser offizieller Logistikpartner ist die Firma Schenker-Arkas.</Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>




      </Tab>
      <Tab eventKey="what" title="| Was |">




        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Was ist Oberhof Getränke?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">Oberhof Getränke® ist die Marke, die Saft und Nektar und mehr herstellt.
        </Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Was ist die Geschichte von Oberhof Getränke?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Text class="text-muted">Es tut uns nicht sehr leid, aber wir haben keine spezielle Geschichte über Oberhof Drinks, die noch daran arbeitet.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Was passiert, wenn du Oberhof Getränke trinkst?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Text class="text-muted">Es tut uns nicht wieder sehr leid, aber es ist nur Juice und kann dir nicht helfen, das Gefühl zu haben, dass du durch das kühle Wasser aus dem heißen Sand springst. <br /> <br /> So fühlt es sich an, dass du nur Juice bist.
           Trinken Sie also einfach und kehren Sie zu dem zurück, was Sie getan haben.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              Was ist der Unterschied von Oberhof Getränke zu anderen Marken?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Text class="text-muted">Komm schon, es ist ein Saft, nur Saft, nur ein Saft. Ja, zumindest ist es gesünder als jeder andere Saft, aber es hilft Ihnen nicht, ein Supermen zu sein, und es bietet Ihnen auch keinen gesunden Körper. <br /> <br /> Wir raten Ihnen, mit dem Training zu beginnen.
           dann werde ich mich nach 2 Wochen besser fühlen, nicht früher als.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              Welche Sprachen auf den Paketen?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Text class="text-muted">Standardsprachen sind Deutsch, Englisch, Arabisch und Russisch.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              Was sind die Barcode-Nummern?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Text class="text-muted">Alle unsere Produkte werden in der Türkei und in Österreich hergestellt.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              Was sind Begriffe?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Text class="text-muted">Unsere gesamte Produktion ist eine Spezialproduktion für verwandte Kunden. Es bedeutet, wenn wir Ihre Bestellung erhalten haben; Wir werden innerhalb von 15 Tagen mit der Produktion Ihrer Bestellungen beginnen, wir haben keinen Lagerbestand. <br /> <br /> Daher wird die gesamte Produktion auf andere Weise speziell für den Kunden produziert. Schneiderjob. Alle unsere Waren sind maximal
           1 Monat alt, als Sie geliefert und Versand inbegriffen.</Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>



      </Tab>
      <Tab eventKey="where" title="| Woher |">

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Wo sind unsere Büros?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">Wir wachsen von Tag zu Tag und für 2020 planen wir 7 weitere Filialen.
                         Derzeit haben wir drei Kontaktbüros in den USA, in Libyen und in der Türkei.
        </Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>


      </Tab>
      <Tab eventKey="why" title="| Warum |">

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Warum Oberhof Getränke?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">In der Zwischenzeit in unserem Büro; wir sitzen
               hinter unseren Schreibtischen und dem Beantworten der Telefone und dem Schreiben von E-Mails wie in jedem anderen Büro fühlen wir uns als sehr wichtige Menschen und die Welt
       kann es nicht umdrehen, wenn wir nicht existieren. Aber wir sind wirklich sehr wichtige Leute, zumindest so wie Sie, also ist entweder unsere Marke. <br /> <br />
                Jetzt wusstest du es; wie. <br /> <br /> In der Tat; Warum die Antwort von Oberhof Drinks® sich selbst lautet. Unsere Flexibilität; Wir sind seit 2010 auf der Bühne und haben einen Pass fertiggestellt
                Buch innerhalb von Entries & Sorties und zweites Buch ist fast fertig. Ein Sprichwort sagt, wer es besser weiß; reist viel oder liest viel. Ja, wir lesen viel und reisen viel.
       Antwort ist Flexibilität, wir sagen, der Weg zum Erfolg ist Flexibilität und gegenseitiges Verständnis. Wir können verstehen, egal in welcher Sprache Sie sprechen. <br /> <br />
                Jetzt wusstest du es; Warum.

        </Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>





      </Tab>
    </Tabs>

  </>
)