import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FaHome, FaBarcode, FaQuestion, FaAddressCard } from 'react-icons/fa';


export const NavigationBar = () => (
  <Navbar sticky="top" bg="#ffff" expand="lg">
    <Navbar.Brand href="/" title="Startseite"><h2><FaHome title="Startseite" /></h2></Navbar.Brand>
    <Navbar.Toggle />
    <Navbar.Collapse>
      <Nav className="ml-auto">
        <Nav.Link href="/Products" title="Produkte"><h3><FaBarcode title="Produkte" /></h3></Nav.Link>
        <Nav.Link href="/Faq" title="Häufig gestellte Fragen"><h4><FaQuestion title="Häufig gestellte Fragen" /></h4></Nav.Link>
        <Nav.Link href="/AboutUs" title="Unternehmen | Adresse | Konzern"><h3><FaAddressCard title="Über uns" /></h3></Nav.Link>
        <Nav.Link href="https://oberhofdrinks.com" title="For English"><h3>en</h3></Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>

)
